import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Not Found</h1>
    <p>Sorry, but you are looking for a page that doesn&#39;t exist...</p>
    <Link
      to="/"
    >
      See Recommendations Here
    </Link>
  </Layout>
)



export default NotFoundPage
